import React, { useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Row from '../components/row/row';
import axios from 'axios';
import { navigate } from 'gatsby';

const ResetPasswordPage = () => {
  const [form, setForm] = useState({
    password: '',
    confirm: '',
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // @todo check if both fields match

    const code = new URLSearchParams(window.location.search).get('code');

    axios
      .post(`${process.env.GATSBY_ADMIN_URL}/auth/reset-password`, {
        code,
        password: form.password,
        passwordConfirmation: form.confirm,
      })
      .then(() => {
        navigate('/app/login');
      })
      .catch((error) => {
        console.log('An error occurred:', error.response);
      });
  };

  return (
    <Layout>
      <SEO title="Reset Password" />
      <Row className="wysiwyg-content">
        <h1>Reset Password</h1>
        <form method="post" onSubmit={handleSubmit} style={{ maxWidth: '300px' }}>
          <label htmlFor="password">
            Enter new password
            <input
              type="password"
              name="password"
              id="password"
              value={form.password}
              onChange={handleInputChange}
              required
            />
          </label>
          <label htmlFor="confirm">
            Confirm new password
            <input
              type="password"
              name="confirm"
              id="confirm"
              value={form.confirm}
              onChange={handleInputChange}
              required
            />
          </label>
          <input type="submit" className="button" value="Reset Password" />
        </form>
      </Row>
    </Layout>
  );
};

export default ResetPasswordPage;
